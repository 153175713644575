<template>
  <div class="foot">
    <div class="banner">
      <img class="background" :src="background">
      <div class="text">
        不断创新驱动变革的发展理念，以技术引领发展
      </div>
      <div class="contract-us">
        <div>
          <contract-form ref="contractForm"></contract-form>
          <el-button v-if="$route.name == 'eMigrate'" @click="toRoute('eMove','')">e键搬工具</el-button>
          <el-button @click="contract">联系我们</el-button>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="navigation-and-code">
        <div class="navigation">
          <div class="navigation-item">
            <div class="main-click">产品服务</div>
            <div class="child-click" @click="toRoute('eBackup','')">云融云备</div>
            <div class="child-click" @click="toRoute('eMove','')">数联整机云迁移</div>
            <div class="child-click" @click="toRoute('eManage','')">数联e键搬家工具</div>
          </div>
          <div class="navigation-item">
            <div class="main-click">客户中心</div>
            <div class="child-click" @click="toRoute('customer','online')">线上支持</div>
            <div class="child-click" @click="toRoute('customer','offline')">线下支持</div>
          </div>
          <div class="navigation-item">
            <div class="main-click">关于我们</div>
            <div class="child-click" @click="toRoute('about','introduce')">公司简介</div>
            <div class="child-click" @click="toRoute('about','mountain')">发展历程</div>
            <div class="child-click" @click="toRoute('about','honor')">资质荣誉</div>
          </div>
        </div>
        <div class="divide"/>
        <div class="code-contract">
          <img class="code" :src="code">
          <div class="contract">
            <div class="email">
              企业邮箱
            </div>
            <div class="email-detail">
				marketing@linkstor.com
            </div>
            <div class="address">
              联系地址
            </div>
            <div class="address-detail">
              广州市番禺区南村镇汉溪大道东362号
            </div>
          </div>
        </div>
      </div>
      <div class="problem-and-copyright">
        <div class="problem-divide"/>
        <div class="problem-main">
          <div class="problem-text" @click="contract">
            遇到问题？联系我们
          </div>
          <div class="problem-img" @click="codeDialog">
            <img class="wechat" :src="wechat"/>
            <!--            <img class="sina" :src="sina" />-->
          </div>
        </div>
        <el-dialog
            class="problem-dialog"
            style="text-align: center"
            :modal-append-to-body="false"
            title="扫码关注服务号了解更多"
            :visible.sync="codeVisible">
          <img class="problem-code" :src="code">
        </el-dialog>
        <div style="margin-top: 1.5%" class="problem-divide"/>
        <div class="copyright-private-map">
          <div class="copyright" @click="icp">
            Copyright © 2023 广州数源畅联科技有限公司 版权所有 粤ICP备19144899号
          </div>
          <!--          <div class="private">-->
          <!--            隐私政策-->
          <!--          </div>-->
          <!--          <div class="private-divide"></div>-->
          <!--          <div class="map">-->
          <!--            网站地图-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contractForm from '../../components/contract-form/index'

export default {
  name: "index",
  components: {contractForm},
  data() {
    return {
      background: require("../../assets/foot/background.png"),
      code: require("../../assets/foot/code.png"),
      sina: require("../../assets/foot/sina.png"),
      wechat: require("../../assets/foot/wechat.png"),
      codeVisible: false,
    }
  },
  methods: {
    contract() {
      this.$refs.contractForm.visibleOpen()
    },
    icp() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
    codeDialog() {
      this.codeVisible = true
    },
    toRoute(name, id) {
      this.$router.push({name: name,params: {id: id}})
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  background: linear-gradient(154.73deg, rgba(13, 126, 191, 1) 0%, rgba(112, 168, 64, 1) 100%);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
  width: 121px;
  height: 42px;
  border: 0px solid #DCDFE6;
}

.problem-text:hover {
  color: #409EFF;
}

.copyright:hover {
  color: #409EFF;
}

.child-click:hover {
  color: #409EFF;
}

.banner {
  width: 1920px;
  height: 220px;
  position: relative;
  .background {
    width: 1920px;
    height: 220px;
  }
  .text {
    position: absolute;
    top: 54.33px;
    left: 624.08px;
    width: 672px;
    height: 47px;
    /** 文本1 */
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 46.34px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
  }

  .contract-us {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 55%;
  }

}

.bottom {
  left: 0px;
  width: 1920px;
  height: 518px;
  background: rgba(23, 28, 39, 1);

  .navigation-and-code {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    width: 1920px;

    .navigation {
      padding-left: 250px;
      display: flex;
      width: 30%;
      justify-content: space-around;
    }

    .code-contract {
      padding-right: 250px;
      width: 26%;
      display: flex;
      justify-content: center;
      .code {
        width: 164px;
        height: 164px;
      }
      .contract {
        margin-left: 50px;
        .email {
          opacity: 0.8;
          /** 文本1 */
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 28.96px;
          color: rgba(255, 255, 255, 1);
          text-align: left;

        }

        .email-detail {
          opacity: 0.6;
          /** 文本1 */
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 21.09px;
          color: rgba(255, 255, 255, 1);
          text-align: left;
        }

        .address {
          padding-top: 65px;
          opacity: 0.8;
          /** 文本1 */
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 28.96px;
          color: rgba(255, 255, 255, 1);
          text-align: left;
        }

        .address-detail {
          opacity: 0.6;
          /** 文本1 */
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 23.17px;
          color: rgba(255, 255, 255, 1);
          text-align: left;

        }
      }
    }
  }

  .problem-and-copyright {
    width: 1400px;
    height: 165px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .problem-divide {
      width: 1400px;
      margin-left: 250px;
      height: 1px;
      opacity: 0.07;
      background: rgba(255, 255, 255, 1);
    }

    .problem-main {
      margin-top: 20px;
      margin-left: 250px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      opacity: 0.6;
      /** 文本1 */
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 23.17px;
      color: rgba(255, 255, 255, 1);
      .problem-dialog {
        width: 300px;
        height: 400px;
      }
      .problem-img {
        margin-right: -200px;
        cursor: pointer;
        .wechat {
          width: 21px;
          height: 17px;
        }
      }

      .problem-text {
        cursor: pointer;
      }
    }

    .copyright-private-map {
      width: 1400px;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .copyright {
        opacity: 0.4;
        margin-left: 250px;
        cursor: pointer;
        /** 文本1 */
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(255, 255, 255, 1);
      }

      //.private {
      //  opacity: 0.4;
      //  margin-left: 3%;
      //  /** 文本1 */
      //  font-size: 14px;
      //  font-weight: 400;
      //  letter-spacing: 0px;
      //  line-height: 20.27px;
      //  color: rgba(255, 255, 255, 1);
      //  text-align: left;
      //}
      //.private-divide {
      //  width: 1px;
      //  height: 15px;
      //  opacity: 0.07;
      //  margin-top: 0.2%;
      //  background: rgba(255, 255, 255, 1);
      //}
      //.map {
      //  margin-right: 50%;
      //  opacity: 0.4;
      //  /** 文本1 */
      //  font-size: 14px;
      //  font-weight: 400;
      //  letter-spacing: 0px;
      //  line-height: 20.27px;
      //  color: rgba(255, 255, 255, 1);
      //  text-align: left;
      //}
    }
  }
}

.main-click {

  /** 文本1 */
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;

}

.child-click {
  cursor: pointer;
  opacity: 0.4;
  margin-top: 20px;
  /** 文本1 */
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.72px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}

.divide {
  width: 1px;
  height: 206px;
  opacity: 0.07;
  background: rgba(255, 255, 255, 1);
}
.problem-code {
  width: 164px;
  height: 164px;
}

</style>