import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/home-center/index"),
    },
    {
        path: "/eBackup",
        name: "eBackup",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/eBackup-center/index"),
    },
    {
        path: "/eBackup-case",
        name: "eBackupCase",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/eBackup-case-center/index"),
    },
    {
        path: "/eBackup-cfile",
        name: "eBackupCFile",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/eBackup-cfile-center/index"),
    },
    {
        path: "/eMove",
        name: "eMove",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/eMove-center/index"),
    },
    {
        path: "/eMigrate",
        name: "eMigrate",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/eMigrate-center/index"),
    },
    {
        path: "/eManage",
        name: "eManage",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/eManage-center/index"),
    },
    {
        path: "/tapeLibrary",
        name: "tapeLibrary",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/tapeLibrary"),
    },
    {
        path: "/oneMachine",
        name: "oneMachine",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/oneMachine"),
    },
    {
        path: "/customer",
        name: "customer",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/customer-center/index"),
    },
    {
        path: "/about",
        name: "about",
        component: () =>
            import(/* webpackChunkName: "customer" */ "../views/about-center/index"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    document.documentElement.scrollTop = 0
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
})
export default router;
