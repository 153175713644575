<template>
	<div class="top">
    <tab ref="tab"></tab>
		<el-carousel v-if="$route.name == 'home'" class="carousel" height="100%" :autoplay="false">
			<el-carousel-item v-for="item in carouselList" :key="item.router">
				<div class="carousel-down">
					<img
						class="carousel-item"
						:src="item.img"/>
					<div class="product">
						<div v-if="item.router == 'eMove'" class="product-name">
							数联整机云迁移LinkedStor CMove
						</div>
						<div v-else-if="item.router == 'eBackup'" class="product-name">
							云融云备LinkedStor Backup
						</div>
						<div v-else class="product-name">
							数联云桌面系统LinkedStor Yundy
						</div>
						<div v-if="item.router == 'eMove'" class="product-title">
							企业数据安全迁移解决方案
						</div>
						<div v-else-if="item.router == 'eBackup'" class="product-title">
							企业数据备份保护解决方案
						</div>
						<div v-else class="product-title">
							企业智能云管理解决方案
						</div>
						<div v-if="item.router == 'eMove'" class="product-use">
							安全
						</div>
						<div v-else-if="item.router == 'eBackup'" class="product-use">
							可靠
						</div>
						<div v-else class="product-use">
							统一
						</div>
						<div class="product-divider">
							|
						</div>
						<div v-if="item.router == 'eMove'" class="product-use-margin">
							无缝
						</div>
						<div v-else-if="item.router == 'eBackup'" class="product-use-margin">
							定制
						</div>
						<div v-else class="product-use-margin">
							智能
						</div>
						<div class="product-divider">
							|
						</div>
						<div v-if="item.router == 'eMove'" class="product-use-margin">
							高效
						</div>
						<div v-else-if="item.router == 'eBackup'" class="product-use-margin">
							可拓展
						</div>
						<div v-else class="product-use-margin">
							合规
						</div>
						<div class="detail">
							<el-button @click="toRoute(item.router)">了解更多</el-button>
						</div>
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>
		<div v-else-if="$route.name == 'eMigrate'" class="eMigrate">
			<img class="eMigrate-background" :src="eMigrateBackground">

			<div class="eMigrate-main">
				<div class="eMigrate-title">
					数联整机云迁移LinkedStor CMove
				</div>
				<div class="eMigrate-solution">
					企业主机数据迁移解决方案
				</div>
				<div class="eMigrate-desc">
					专为企业提供数据保护和恢复功能的解决方案
				</div>
				<div class="experience">
					<el-button @click="experience('eMigrateTab')">立即体验</el-button>
				</div>
			</div>
		</div>
		<div v-else-if="$route.name == 'eMove'" class="eMove">
			<img class="eMove-background" :src="eMoveBackground">

			<div class="eMove-main">
				<div class="eMove-title">
					数联e键搬家工具LinkedStor ALLMove
				</div>
				<div class="eMove-solution">
					云电脑数据一键迁移工具
				</div>
				<div class="eMove-desc">
					一键迁移，轻松换机
				</div>
				<div class="experience">
					<el-button @click="experience('eMoveTab')">立即体验</el-button>
				</div>
			</div>
		</div>
		<div v-else-if="$route.name == 'eManage'" class="eManage">
			<img class="eManage-background" :src="eManageBackground">

			<div class="eManage-main">
				<div class="eManage-title">
					数联云桌面系统LinkedStor Yundy
				</div>
				<div class="eManage-solution">
          企业智能云管理解决方案
				</div>
				<div class="eManage-desc">
					专为企业提供智能云管理的解决方案
				</div>
				<div class="experience">
					<el-button @click="experience('eManageTab')">立即体验</el-button>
				</div>
			</div>
		</div>
    <div v-else-if="$route.name == 'tapeLibrary'" class="eManage">
			<img class="eManage-background" :src="eManageBackground">

			<div class="eManage-main">
				<div class="eManage-title">
          LinkedStor虚拟磁带库
				</div>
        
				<div class="experience">
					<el-button @click="experience('eManageTab')">立即体验</el-button>
				</div>
			</div>
		</div>
    <div v-else-if="$route.name == 'oneMachine'" class="eManage">
			<img class="eManage-background" :src="eManageBackground">

			<div class="eManage-main">
				<div class="eManage-title">
          LinkedStor云融灾备一体机
				</div>
        <div class="eManage-solution">
          实时备份·瞬时可用·自动化灾难恢复·远程复制
				</div>
				<div class="experience">
					<el-button @click="experience('eManageTab')">立即体验</el-button>
				</div>
			</div>
		</div>
		<div v-else-if="$route.name == 'customer'" class="customer">
			<img class="customer-background" :src="customerBackground">

			<div class="customer-main">
				<div class="customer-title">
					客户中心
				</div>
				<div class="customer-desc">
					搭建数字工具链一体化平台
				</div>
			</div>
		</div>
		<div v-else-if="$route.name == 'about'" class="about">
			<img class="about-background" :src="aboutBackground">

			<div class="about-main">
				<div class="about-title">
					关于我们
				</div>
				<div class="about-desc">
					致力于推进数字化技术的创新服务
				</div>
			</div>
		</div>
		<div v-else class="eBackup">
			<img class="eBackup-background" :src="eBackupBackground">
			<div class="eBackup-main">
				<div class="eBackup-title">
					云融云备LinkedStor Backup
				</div>
				<div class="eBackup-solution">
					企业数据备份保护解决方案
				</div>
				<div class="eBackup-desc">
					专为企业提供数据保护和恢复功能的解决方案
				</div>
				<div class="experience">
					<el-button @click="experience('eBackupTab')">立即体验</el-button>
				</div>
			</div>
		</div>
    <Nav class="nav" v-if="$route.name != 'home' && $route.name != 'customer' && $route.name != 'about'"/>
	</div>
</template>

<script>
import Tab from "./components/tab";
import Nav from "../../components/nav/index"
export default {
	name: "topPage",
	components: {Tab, Nav},
	data() {
		return {
			eBackupBackground: require("../../assets/top/eBackup-background.png"),
			eMoveBackground: require("../../assets/top/eMove-background.png"),
			eManageBackground: require("../../assets/top/eManage-background.png"),
			eMigrateBackground: require("../../assets/top/eMove-background.png"),
			customerBackground: require("../../assets/top/customer-background.png"),
			aboutBackground: require("../../assets/top/about-background.png"),
			carouselHeight: window.screen.width * 11 / 32 + 'px',
			carouselList: [
				{
					img: require("../../assets/top/carousel1.png"),
					router: "eBackup",
					active: "产品服务",
					activeItem: "eBackup",
				},
				{
					img: require("../../assets/top/carousel2.png"),
					router: "eMove",
					active: "产品服务",
					activeItem: "e键搬",
				},
				{
					img: require("../../assets/top/carousel3.png"),
					router: "eManage",
					active: "产品服务",
					activeItem: "e键管",
				},
			],
		}
	},
	methods: {
    experience() {
      this.$refs.tab.contract()
    },
		toRoute(name) {
			if (name == "") {
				this.$router.push({name: "home"})
			}
			this.$router.push({name})
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .el-button {
	background: linear-gradient(154.73deg, rgba(13, 126, 191, 1) 0%, rgba(112, 168, 64, 1) 100%);
	color: rgba(255, 255, 255, 1);
	font-size: 14px;
	font-weight: 400;
	width: 121px;
	height: 42px;
	border: 0px solid #DCDFE6;
}

.experience {
	margin-top: 50px;
}

.top {
  width: 1920px;
  height: 780px;
  .carousel {
    width: 1920px;
    height: 780px;
    .carousel-item {
      width: 1920px;
      height: 780px;
    }
    .product {
      display: block;
      position: fixed;
      text-align: left;
      width: 600px;
      color: rgba(255, 255, 255, 1);
      top: 265px;
      left: 260px;
      .product-name {
        //width: 100px;
		  display: inline-block;
		  padding: 0 10px;
        height: 42px;
        border-radius: 0px 10px 10px 10px;
        border: 1px solid rgba(255, 255, 255, 1);

        /** 文本1 */
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 42px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
      }

      .product-title {
        margin-top: 6px;
        width: 600px;
        height: 73px;
        /** 文本1 */
        font-size: 50px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 72.4px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;
      }

      .product-divider {
        display: inline-block;
        margin-left: 25px;
        line-height: 43.44px;
        color: rgba(255, 255, 255, 1);
        font-weight: 400;
        opacity: 0.21;
        font-size: 30px;
      }

      .product-use {
        display: inline-block;
        text-align: left;
        /** 文本1 */
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 43.44px;
        color: rgba(255, 255, 255, 1);
        vertical-align: top;
      }
      .product-use-margin {
        display: inline-block;
        text-align: left;
        /** 文本1 */
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 43.44px;
        color: rgba(255, 255, 255, 1);
        vertical-align: top;
        margin-left: 25px;
      }

      .detail {
        margin-top: 50px;
      }

    }
  }
  .eBackup {
    position: relative;
    text-align: left;
    width: 1920px;
    .eBackup-background {
      width: 1920px;
      height: 780px;
    }
    .eBackup-tab {
      position: absolute;
    }

    .eBackup-main {
      position: absolute;
      top: 265px;
      margin-left: 260px;
      //width: 432px;

      .eBackup-title {
        /** 文本1 */
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 52px;
        color: rgba(255, 255, 255, 1);
      }

      .eBackup-solution {
        /** 文本1 */
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);
      }

      .eBackup-desc {
        margin-top: 12px;
        /** 文本1 */
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .eMigrate {
    width: 1920px;
    position: relative;
    text-align: left;
    .eMigrate-background {
      width: 1920px;
      height: 780px;
    }
    .eMigrate-tab {
      position: absolute;
    }

    .eMigrate-main {
      position: absolute;
      top: 265px;
      margin-left: 260px;
      //width: 432px;

      .eMigrate-title {
        /** 文本1 */
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);
      }

      .eMigrate-solution {
        /** 文本1 */
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);

      }

      .eMigrate-desc {
        margin-top: 12px;
        /** 文本1 */
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .eMove {
    position: relative;
    text-align: left;
    width: 1920px;
    .eMove-background {
      width: 1920px;
      height: 780px;
    }
    .eMove-tab {
      position: absolute;
    }

    .eMove-main {
      position: absolute;
      top: 265px;
      margin-left: 260px;
      //width: 432px;

      .eMove-title {
        /** 文本1 */
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);
      }

      .eMove-solution {
        /** 文本1 */
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);

      }

      .eMove-desc {
        margin-top: 12px;
        /** 文本1 */
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .eManage {
    position: relative;
    text-align: left;
    width: 1920px;
    .eManage-background {
      width: 1920px;
      height: 780px;
    }
    .eManage-tab {
      position: absolute;
    }

    .eManage-main {
      position: absolute;
      top: 265px;
      margin-left: 260px;
      //width: 432px;

      .eManage-title {
        /** 文本1 */
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 52px;
        color: rgba(255, 255, 255, 1);
      }

      .eManage-solution {
        /** 文本1 */
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);
      }

      .eManage-desc {
        margin-top: 12px;
        /** 文本1 */
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .customer {
    position: relative;
    text-align: left;
    .customer-background {
      width: 1920px;
      height: 780px;
    }
    .customer-tab {
      position: absolute;
    }

    .customer-main {
      position: absolute;
      top: 265px;
      margin-left: 260px;
      width: 432px;

      .customer-title {
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);

      }

      .customer-desc {
        margin-top: 12px;
        /** 文本1 */
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .about {
    position: relative;
    text-align: left;
    width: 1920px;
    .about-background {
      width: 1920px;
      height: 780px;
    }
    .about-tab {
      position: absolute;
    }

    .about-main {
      position: absolute;
      top: 265px;
      margin-left: 260px;
      width: 432px;

      .about-title {
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);

      }

      .about-desc {
        margin-top: 12px;
        /** 文本1 */
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .nav {
    height: 109px;
  }
}

</style>