<template>
	<div class="nav1">
		<div class="nav1_wrap">
			<ul>
				<li class="nav1_wrap_eBackup" @click="toRoute('eBackup')" :class="{active1: routerName === 'eBackup' || routerName === 'eBackupCFile' || routerName === 'eBackupCase'}" @mouseenter="changeActive('eBackup')" @mouseleave="resetActive">
					<img :src="routerName === 'eBackup' || routerName === 'eBackupCFile' || routerName === 'eBackupCase' ? eBackupActive : eBackup"><!--@mouseover="changeActive(0)"  || $route.name === 'eBackup-cfile' || $route.name === 'eBackup-case'-->
					<p class="nav1_wrap_title">云融云备</p>
				</li>
				<li class="nav1_wrap_eMove" @click="toRoute('eMigrate')" :class="{active1: routerName === 'eMigrate' }" @mouseenter="changeActive('eMigrate')" @mouseleave="resetActive">
					<img :src="routerName === 'eMigrate' ? eMoveActive : eMove">
					<p class="nav1_wrap_title">数联整机云迁移</p>
				</li>
				<li class="nav1_wrap_eMove" @click="toRoute('eMove')" :class="{active1:  routerName === 'eMove'}" @mouseenter="changeActive('eMove')" @mouseleave="resetActive">
					<img :src="routerName === 'eMove'  ? banjia2 : banjia1">
					<p class="nav1_wrap_title">数联e键搬家工具</p>
				</li>
				<li class="nav1_wrap_eManage" @click="toRoute('eManage')" :class="{active1: routerName === 'eManage'}" @mouseenter="changeActive('eManage')" @mouseleave="resetActive">
					<img :src="routerName === 'eManage' ? eManageActive : eManage">
					<p class="nav1_wrap_title">数联云桌面系统</p>
				</li>
				<li class="nav1_wrap_eManage" @click="toRoute('tapeLibrary')" :class="{active1: routerName === 'tapeLibrary'}" @mouseenter="changeActive('tapeLibrary')" @mouseleave="resetActive">
					<img :src="routerName === 'tapeLibrary' ? tapeLibrary2 : tapeLibrary1">
					<p class="nav1_wrap_title"> LinkedStor虚拟磁带库</p>
				</li>
				<li class="nav1_wrap_eManage" @click="toRoute('oneMachine')" :class="{active1: routerName === 'oneMachine'}" @mouseenter="changeActive('oneMachine')" @mouseleave="resetActive">
					<img :src="routerName === 'oneMachine' ? oneMachine2 : oneMachine1">
					<p class="nav1_wrap_title"> LinkedStor云融灾备一体机</p>
				</li>
				
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: "Nav",
	data() {
		return {
			eBackup: require('../../assets/nav/eBackup.png'),
			eBackupActive: require('../../assets/nav/eBackup-active.png'),
			eMove: require('../../assets/nav/eMove.png'),
			eMoveActive: require('../../assets/nav/eMove-active.png'),
			eManage: require('../../assets/nav/eManage.png'),
			banjia1: require('../../assets/icon/2-1.png'),
			banjia2: require('../../assets/icon/2-2.png'),
			eManageActive: require('../../assets/nav/eManage-active.png'),
			routerName: this.$route.name,
			
			oneMachine1:require("../../assets/icon/3-1.png"),
			oneMachine2:require("../../assets/icon/3-2.png"),
			tapeLibrary1:require("../../assets/icon/4-1.png"),
			tapeLibrary2:require("../../assets/icon/4-2.png"),
		}
	},
	methods: {
		changeActive(str){
			this.routerName = str
		},
		resetActive(){
			this.routerName = this.$route.name
		},
    toRoute(name) {
      if (name == "") {
        this.$router.push({name: "home"})
      }
      this.$router.push({name})
    },
	},
	created() {
		// this.$router.onReady(() => {
		// 	this.routerName = this.$route.name// 在 onReady 回调中获取 $route.name 值
		// });
	},
	computed: {},
	// 监听,当路由发生变化的时候执行
	watch: {
		$route(to){
			this.routerName = to.name
		}
	},

}
</script>

<style lang="scss" scoped>
.nav1 {
	width: 1920px;
	height: 109px;
	/* 内容上下居中 */
	line-height: 109px;
	background-color: rgba(245, 248, 255, 1);
	/* 字体大小*/
	font-size: 18px;
	display: flex;
	justify-content: center;
	.nav1_wrap{
		width: 1200px;
		height: 109px;
		.nav1_wrap_eBackup {
			cursor: pointer;
			.nav1_wrap_title{
				margin-top: 6px;
				//width: 50px;
				height: 26.93px;
				opacity: 1;
				/** 文本1 */
				font-size: 17.95px;
				font-weight: 300;
				letter-spacing: 0;
				line-height: 25.99px;
				text-align: center;
				vertical-align: top;
			}
		}
		.nav1_wrap_eMove {
			cursor: pointer;
			.nav1_wrap_title{
				margin-top: 6px;
				//width: 50px;
				height: 26.93px;
				opacity: 1;
				/** 文本1 */
				font-size: 17.95px;
				font-weight: 300;
				letter-spacing: 0;
				line-height: 25.99px;
				text-align: center;
				vertical-align: top;
			}
		}
		.nav1_wrap_eManage {
			cursor: pointer;
			.nav1_wrap_title{
				margin-top: 6px;
				//width: 50px;
				height: 26.93px;
				opacity: 1;
				/** 文本1 */
				font-size: 17.95px;
				font-weight: 300;
				letter-spacing: 0;
				line-height: 25.99px;
				text-align: center;
				vertical-align: top;
			}
		}
	}
}
.nav1 ul {
	height: 109px;
	display: flex;
	justify-content: space-between;
}

.nav1 ul li {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 109px;
	float: left;
	flex-grow: 1;
}

.active1 {
	background-color: white;
  .nav1_wrap_title {
    color: #409EFF;
  }
}

.nav1 ul li:not(.active1) {
	background-color: rgba(245, 248, 255, 1);
}

.nav1 ul li img {
	margin-top: 25px;
	width: 33px;
	height: 33px;
}
</style>