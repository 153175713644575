<template>
  <div id="app">
    <div class="top">
      <topPage></topPage>
    </div>
    <router-view />
    <div class="foot">
      <foot-page></foot-page>
    </div>
  </div>
</template>

<script>
import topPage from './components/top/index'
import footPage from './components/foot/index'
export default {
  components: { topPage, footPage }
}
</script>
<style lang="scss">
#app {
  font-family: "思源黑体";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #4c4c4c;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
